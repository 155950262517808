import React from "react"
import { theme } from "../Styles/theme"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import Header from "../Components/header"
import LandingLayout from "../Components/landingLayout"
import Brands from "../Components/brands"
import Cta from "../Components/cta"
import { Section } from "../Styles/section"
import { SectionHero, SectionTagLine } from "../Styles/text"
import { GlobalStyles } from "../Styles/global"
import styled from "@emotion/styled"

const PageTitle = styled.h2`
  padding: 4rem 3rem 3rem 3rem;
  text-align: center;
  max-width: 1200px;
  margin: auto;
  line-height: 1.4;
`

export default () => (
  <ThemeProvider theme={theme}>
    <Global styles={GlobalStyles} />
    <LandingLayout>
      <Section backgroundColor="secondary">
        <PageTitle>Thank you for joining the community!</PageTitle>
        <SectionHero style={{ maxWidth: "800px", margin: "0 auto", fontSize: "1.2rem"}}>
          Here are the next steps:
          <br />
          <br />
          <ol>
            <li>
              You should've already received an email from Podia to access my flagship Javascript course Jabjabjavascript. Just use that email to register for an account.
            </li>
            <li>
          Click <a href="https://frontend-collective.circle.so/join?invitation_token=1462483c63a14b35cd00da62a9953a8bb253d26f-227fed27-1a37-4c30-b0c9-6cc85a412832"> here </a> to get access to the exclusive Frontend Collective community
            </li>
          </ol>
          <br />
          That's it. See you inside!
        </SectionHero>
      </Section>
    </LandingLayout>
  </ThemeProvider>
)
